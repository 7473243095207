// ActionNodePopup.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faClone } from "@fortawesome/free-solid-svg-icons";
import { formatId, formatDate } from "../utils/formatUtils.js";

const ActionNodePopup = ({
  node,
  handleClosePopup,
  copied,
  handleIdClick,
  formatContent,
}) => {
  // Extract data from node.data.item if it exists, otherwise use node directly
  const nodeData = node.data?.item || node;

  return (
    <div className="popup-content">
      <div className="main-row">
        <FontAwesomeIcon className="node-icon-popup" icon={faGear} />
        <div className="title-row">
          <div className="title-header">
            {nodeData.function_name || "Action Node"}
          </div>
          <button
            className="close-button-right-drawer"
            onClick={handleClosePopup}
          >
            &times;
          </button>
        </div>
      </div>
      <hr className="divider-popup" />
      {Object.entries(nodeData)
        .filter(([key]) => ["id", "created_at"].includes(key))
        .map(([key, value]) => {
          const isIdField = key.toLowerCase() === "id";
          const displayValue =
            isIdField && value !== "None"
              ? formatId(value)
              : value === null || value === ""
              ? "N/A"
              : value;

          return (
            <div key={key}>
              <p className="key-value-pair">
                <strong>
                  {key === "created_at"
                    ? "START"
                    : key === "model_name"
                    ? "MODEL"
                    : key.toUpperCase()}
                </strong>
                <span
                  className={
                    isIdField && value !== "None" && displayValue !== "N/A"
                      ? "id-tooltip"
                      : ""
                  }
                  title={
                    isIdField && value !== "None" && displayValue !== "N/A"
                      ? value
                      : ""
                  }
                  onClick={() => {
                    if (
                      isIdField &&
                      value !== "None" &&
                      displayValue !== "N/A"
                    ) {
                      handleIdClick(value);
                    }
                  }}
                  style={{ cursor: isIdField ? "pointer" : "default" }}
                >
                  {isIdField && value !== "None" && displayValue !== "N/A" && (
                    <span
                      onClick={() => handleIdClick(value)}
                      className="copy-icon-container"
                    >
                      {copied && <span className="copied-style">Copied!</span>}
                      <FontAwesomeIcon className="copy-icon" icon={faClone} />
                    </span>
                  )}
                  {key === "created_at"
                    ? formatDate(displayValue)
                    : typeof displayValue === "object"
                    ? JSON.stringify(displayValue, null, 2)
                    : displayValue}
                </span>
              </p>
            </div>
          );
        })}
      <hr className="divider-popup" />
      {nodeData.output && (
        <div>
          <strong className="description-content">OUTPUT</strong>
          <pre className="formatted-content">
            {formatContent(nodeData.output)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default ActionNodePopup;
