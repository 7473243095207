import React from "react";
import Calendar from "react-calendar";
import FilterDropdown from "./FilterDropdown.js";
import { formatDateRange } from "../utils/formatUtils.js";

function FilterRow({
  activeFilters,
  toggleFilterOptions,
  handleStatusFilter,
  setActiveFilters,
  openDropdown,
  handleNameKeyPress,
  handleSearch,
  handleDateFilter,
  customers,
  filterWorkflowsByCustomer,
  selectedCustomer,
  setSelectedCustomer,
  showCalendar,
  calendarRef,
  handleChange,
  selectedDateRange,
  submitDateRange,
  submitButtonRef,
  isSuperdupont,
}) {
  const statusOptions = [
    { value: "failed", label: "Failed" },
    { value: "error", label: "Warning" },
    { value: "completed", label: "Success" },
    { value: "created", label: "No Status" },
  ];

  const getStatusDisplayValue = (status) => {
    if (!status) return "Last Status";
    const option = statusOptions.find((opt) => opt.value === status);
    return option ? option.label : status;
  };

  const renderStatusDropdown = () => (
    <div>
      {statusOptions.map(({ value, label }) => (
        <div
          key={value}
          className="dropdown-option"
          onClick={() => handleStatusFilter(value)}
        >
          {label}
        </div>
      ))}
    </div>
  );

  const renderNameDropdown = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by name..."
          onChange={(e) => {
            const searchTerm = e.target.value.trim();
            setActiveFilters((prev) => ({ ...prev, name: searchTerm }));
          }}
          onKeyDown={handleNameKeyPress}
          className="App-search-input styled-search-input"
        />
        <button
          className="App-search-submit"
          onClick={() => handleSearch(activeFilters.name)}
        >
          ✓
        </button>
      </div>
    </div>
  );

  const renderDateDropdown = () => (
    <div>
      {["Last 7 Days", "Last 30 Days", "Custom date range"].map((option) => (
        <div
          key={option}
          className="dropdown-option"
          onClick={() =>
            handleDateFilter(option === "Custom date range" ? "Custom" : option)
          }
        >
          {option}
        </div>
      ))}
    </div>
  );

  const renderCustomerDropdown = () => (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        className="dropdown-option"
        onClick={() => {
          filterWorkflowsByCustomer("");
          toggleFilterOptions(null);
        }}
      >
        All Customers
      </div>
      {customers.map((customer) => (
        <div
          key={customer.id}
          className="dropdown-option"
          onClick={() => {
            filterWorkflowsByCustomer(customer.name);
            toggleFilterOptions(null);
          }}
        >
          {customer.name}
        </div>
      ))}
    </div>
  );

  return (
    <div className="App-filter-row">
      <FilterDropdown
        type="status"
        displayValue={getStatusDisplayValue(activeFilters.status)}
        isActive={activeFilters.status}
        onClear={() => setActiveFilters((prev) => ({ ...prev, status: null }))}
        toggleFilterOptions={toggleFilterOptions}
        openDropdown={openDropdown}
      >
        {renderStatusDropdown()}
      </FilterDropdown>
      <FilterDropdown
        type="name"
        displayValue={activeFilters.name || "Name"}
        isActive={activeFilters.name}
        onClear={() => setActiveFilters((prev) => ({ ...prev, name: null }))}
        toggleFilterOptions={toggleFilterOptions}
        openDropdown={openDropdown}
      >
        {renderNameDropdown()}
      </FilterDropdown>
      <FilterDropdown
        type="lastRun"
        displayValue={
          typeof activeFilters.lastRun === "string"
            ? activeFilters.lastRun
            : formatDateRange(activeFilters.lastRun)
        }
        isActive={activeFilters.lastRun}
        onClear={() => setActiveFilters((prev) => ({ ...prev, lastRun: null }))}
        toggleFilterOptions={toggleFilterOptions}
        openDropdown={openDropdown}
      >
        {renderDateDropdown()}
      </FilterDropdown>
      {isSuperdupont && (
        <div className="App-filter-row-customer-env">
          <FilterDropdown
            type="customer"
            displayValue={selectedCustomer || "Customer"}
            isActive={selectedCustomer}
            onClear={() => setSelectedCustomer(null)}
            toggleFilterOptions={toggleFilterOptions}
            openDropdown={openDropdown}
            className="with-divider"
          >
            {renderCustomerDropdown()}
          </FilterDropdown>
        </div>
      )}
      {showCalendar && (
        <div ref={calendarRef} className="App-date-range-picker-main">
          <Calendar
            onChange={handleChange}
            value={selectedDateRange}
            selectRange={true}
            className="custom-calendar-main custom-calendar"
          />
          <button
            ref={submitButtonRef}
            className="App-submit-date-range-main"
            onClick={submitDateRange}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterRow;
