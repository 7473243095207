import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { formatId, formatDate } from "../utils/formatUtils.js";

const EmptyDrawerMessagePopup = ({
  currentWorkflowName,
  workflowDescription,
  onClose,
  outputs,
  workflowInstanceId,
  workflowInstanceCreatedAt,
  copied,
  handleIdClick,
}) => {
  const renderOutputs = (outputs) => {
    if (Array.isArray(outputs) && outputs.length > 0) {
      return outputs.map((outputObj, index) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return (
          <React.Fragment key={index}>
            <div className="output-row">
              {outputObj.key !== "N/A" && outputObj.key && (
                <p className="key-formatted" style={{ textAlign: "left" }}>
                  {outputObj.key}
                </p>
              )}
              <p>
                {outputObj.output
                  ? outputObj.output.split(urlRegex).map((part, i) => {
                      if (part.match(urlRegex)) {
                        return (
                          <a
                            key={i}
                            href={part}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {part}
                          </a>
                        );
                      }
                      return part;
                    })
                  : "No output data"}
              </p>
            </div>
            {index < outputs.length - 1 && (
              <hr className="divider-popup-output" />
            )}
          </React.Fragment>
        );
      });
    }
    return <p>No output available</p>;
  };

  return (
    <div className="popup-content">
      <div className="main-row">
        <div className="title-row">
          <div className="title-header">
            {currentWorkflowName || "Workflow"}
          </div>
          <button className="close-button-right-drawer" onClick={onClose}>
            &times;
          </button>
        </div>
      </div>
      <div className="description-text-content">
        {workflowDescription || "No description available"}
      </div>
      <hr className="divider-popup" />
      <div>
        <p className="key-value-pair">
          <strong>ID:</strong>
          <span
            className="id-tooltip"
            title={workflowInstanceId}
            onClick={() => handleIdClick(workflowInstanceId)}
          >
            <FontAwesomeIcon className="copy-icon" icon={faClone} />
            {formatId(workflowInstanceId)}
            <span
              onClick={() => handleIdClick(workflowInstanceId)}
              className="handle-id-click"
            >
              {copied && <span className="copied-style">Copied!</span>}
            </span>
          </span>
        </p>
        <p className="key-value-pair">
          <strong>START:</strong>
          <span>{formatDate(workflowInstanceCreatedAt)}</span>
        </p>
      </div>
      <hr className="divider-popup" />
      <strong className="description-content">
        {outputs?.length === 1 ? "OUTPUT" : "OUTPUTS"}
      </strong>
      <div style={{ marginBottom: "8px" }}></div>
      <div className="formatted-content">{renderOutputs(outputs)}</div>
    </div>
  );
};

export default EmptyDrawerMessagePopup;
