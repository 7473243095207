import React from "react";
import "../App.css";

const ErrorPopup = ({ errorMessage, onClose }) => {
  const errors = Array.isArray(errorMessage) ? errorMessage : [errorMessage];

  const renderError = (error) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return error.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  return (
    <div className="output-popup-overlay" onClick={onClose}>
      <div
        className="output-popup-content"
        onClick={(e) => e.stopPropagation()}
      >
        {errors.length > 1 && (
          <div className="output-navigation">
            <ul>
              {errors.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => {
                      document.getElementById(`error-${index}`).scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    <div>___</div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div
          className="output-content"
          style={errors.length === 1 ? { paddingLeft: "20px" } : {}}
        >
          <div className="output-popup-header">
            <div className="output-popup-title">Error Details</div>
            <button className="output-close-button" onClick={onClose}>
              ×
            </button>
          </div>
          <hr className="divider-popup" />
          <ul className="error-popup-list">
            {errors.map((error, index) => (
              <React.Fragment key={index}>
                <li id={`error-${index}`} style={{ marginBottom: "12px" }}>
                  {renderError(error)}
                </li>
                {index < errors.length - 1 && <hr className="divider-popup" />}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ErrorPopup;
