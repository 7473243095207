import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config.js";
import history from "./utils/history.js";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { SuperdupontProvider } from "./contexts/SuperdupontContext.js";
import { BreadcrumbsProvider } from "./contexts/BreadcrumbsContext.js";
import { EnvironmentProvider } from "./contexts/EnvironmentContext.js";
import { FilterProvider } from "./contexts/FilterContext.js";
import { ObservableViewProvider } from "./contexts/ObservableViewContext.js";
import { UserProvider } from "./contexts/UserContext.js";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const ensureHttps = (uri) => {
  if (!uri) return uri;
  if (uri.includes("localhost")) return uri;
  return uri.replace("http://", "https://");
};

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  useFormData: true,
  authorizationParams: {
    redirect_uri: ensureHttps(window.location.origin),
    ...(config.audience ? { audience: config.audience } : null),
    scope: "openid profile email",
  },
  cacheLocation: "localstorage",
};

const forceHttps = () => {
  if (
    window.location.protocol === "http:" &&
    !window.location.hostname.includes("localhost")
  ) {
    window.location.href = window.location.href.replace("http:", "https:");
    return true;
  }
  return false;
};

const AppWithProviders = () => {
  if (forceHttps()) {
    return null;
  }

  return (
    <Auth0Provider {...providerConfig}>
      <Router>
        <React.StrictMode>
          <EnvironmentProvider>
            <UserProvider>
              <SuperdupontProvider>
                <ObservableViewProvider>
                  <FilterProvider>
                    <BreadcrumbsProvider>
                      <ReactFlowProvider>
                        <App />
                      </ReactFlowProvider>
                    </BreadcrumbsProvider>
                  </FilterProvider>
                </ObservableViewProvider>
              </SuperdupontProvider>
            </UserProvider>
          </EnvironmentProvider>
        </React.StrictMode>
      </Router>
    </Auth0Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<AppWithProviders />);
