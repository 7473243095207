import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import whiteLogo from "./assets/white_qurrent_logo.png";
import superdupontImage from "./assets/superdupont.jpg";
import "./App.css";
import { useSuperdupont } from "./contexts/SuperdupontContext.js";
import { useObservableView } from "./contexts/ObservableViewContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
  faSignOutAlt,
  faChevronDown,
  faBug,
  faSun,
} from "@fortawesome/free-solid-svg-icons";
import { useApiUtils } from "./utils/apiUtils.js";
import { handleLogout } from "./utils/authUtils.js";
import { Link } from "react-router-dom";
import config from "./config.js";

function Header({ userIsQurrent }) {
  const { endSession } = useApiUtils();

  const { logout, user, getAccessTokenSilently } = useAuth0();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  const { isSuperdupont, toggleSuperdupont } = useSuperdupont(false);
  const { isObservableViewActive, toggleObservableView } = useObservableView();
  const [isSuperdupontActive, setIsSuperdupontActive] = useState(
    JSON.parse(sessionStorage.getItem("superdupontMode")) || false
  );

  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    console.log("Header: userIsQurrent", userIsQurrent);
    if (!userIsQurrent && isObservableViewActive) {
      setTimeout(() => {
        toggleObservableView();
      }, 0);
    }
  }, [userIsQurrent, isObservableViewActive, toggleObservableView]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
    document.documentElement.classList.toggle("dark-mode", darkMode);
    document.body.classList.toggle("light-mode", !darkMode);
    document.documentElement.classList.toggle("light-mode", !darkMode);
  }, [darkMode]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      endSession();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleLogoutClick = () => {
    handleLogout({
      logout,
      getAccessTokenSilently,
      apiUtils: { endSession },
    });
  };

  const toggleDarkMode = () => {
    const isDarkMode = !darkMode;
    setDarkMode(isDarkMode);
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    console.log("SESSION ID HERE: ", sessionStorage.getItem("session_id"));
  };

  const handleSuperdupontToggle = () => {
    if (userIsQurrent) {
      toggleSuperdupont();
      setIsSuperdupontActive((prevState) => {
        const newState = !prevState;
        sessionStorage.setItem("superdupontMode", JSON.stringify(newState));
        if (!newState) {
          setTimeout(() => {
            toggleObservableView();
          }, 0);
        }
        return newState;
      });
    }
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".App-dropdown")) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="App-header">
      <div className="App-header-left">
        <Link to="/">
          <img src={whiteLogo} className="App-logo" alt="logo" />
        </Link>
      </div>
      <div className="App-header-right">
        <div className="App-account">
          <div className="App-dropdown">
            <div className="App-username-container" onClick={toggleDropdown}>
              <span className="App-username">
                {isSuperdupontActive && isObservableViewActive && (
                  <FontAwesomeIcon icon={faBug} className="debug-icon-header" />
                )}
                {isSuperdupontActive && (
                  <img
                    src={superdupontImage}
                    className="agent-icon-header"
                    alt="superdupont"
                  />
                )}
                {user
                  ? user.given_name && user.family_name
                    ? `${user.given_name} ${user.family_name}`
                    : user.name || user.email
                  : "Username"}{" "}
              </span>
              <button className="App-dropdown-button">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`small-chevron ${dropdownOpen ? "rotated" : ""}`}
                />
              </button>
            </div>
            {dropdownOpen && (
              <div className="App-dropdown-content">
                {userIsQurrent && (
                  <>
                    <button
                      onClick={handleSuperdupontToggle}
                      className={`superdupont-toggle-button ${
                        isSuperdupontActive ? "active" : ""
                      }`}
                    >
                      <div className="superdupont-icon">
                        <img
                          src={superdupontImage}
                          className="shrinked-image"
                          alt="superdupont"
                        />
                      </div>
                      Toggle Superdupont
                    </button>
                    {isSuperdupontActive && (
                      <button
                        onClick={toggleObservableView}
                        className={`observable-view-toggle-button ${
                          isObservableViewActive ? "active" : ""
                        }`}
                      >
                        <div className="observable-view-icon">
                          <FontAwesomeIcon icon={faBug} />
                        </div>
                        Toggle Debug Mode
                      </button>
                    )}
                  </>
                )}
                <button
                  onClick={toggleDarkMode}
                  className={`dark-mode-button ${darkMode ? "active" : ""}`}
                >
                  <div className="dark-mode-icon">
                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                  </div>
                  {darkMode ? "Toggle Light Mode" : "Toggle Dark Mode"}
                </button>
                <button onClick={handleLogoutClick} className={`logout-button`}>
                  <div className="logout-icon">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                  Logout
                </button>
                <div className="version-text">
                  {}v{config.version || "ersion not found"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
