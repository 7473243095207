import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./App.css";
import Header from "./Header.js";
import { useAuth0 } from "@auth0/auth0-react";
import ReactFlow, { Background, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import ActionNodePopup from "./workflow_components/ActionNodePopup.js";
import LLMCallNodePopup from "./workflow_components/LLMCallNodePopup.js";
import ObservableNodePopup from "./workflow_components/ObservableNodePopup.js";
import AgentNodePopup from "./workflow_components/AgentNodePopup.js";
import ErrorPopup from "./workflow_components/ErrorPopup.js";
import ActualErrorPopup from "./workflow_components/ActualErrorPopup.js";
import LeftDrawer from "./workflow_components/LeftDrawer.js";
import WorkflowTable from "./workflow_components/WorkflowTable.js";
import Breadcrumbs from "./workflow_components/Breadcrumbs.js";
import EmptyDrawerMessagePopup from "./workflow_components/EmptyDrawerMessagePopup.js";
import { useObservableView } from "./contexts/ObservableViewContext.js";

import {
  formatNodeName,
  formatId,
  formatContent,
  getDomainFromEmail,
} from "./utils/formatUtils.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faPenToSquare,
  faGear,
  faComments,
  faAnglesLeft,
  faAnglesRight,
  faAnglesDown,
} from "@fortawesome/free-solid-svg-icons";
import { useBreadcrumbs } from "./contexts/BreadcrumbsContext.js";
import { getSortIcon, getStatusIcon } from "./utils/formatUtils.js";
import {
  getLayoutedElements,
  combineRuns,
  toggleNodeVisibility,
} from "./utils/workflowUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";

function WorkflowDetails({ userIsQurrent }) {
  const {
    fetchFilteredInstances,
    fetchChildWorkflows,
    fetchWorkflowStructure,
    fetchLatestWorkflowInstance,
    fetchLlmCallSummary,
    fetchObservableSummary,
    fetchWorkflowInstanceInfo,
    fetchWorkflowVersions,
  } = useApiUtils();

  const { setCenter } = useReactFlow();

  const [workflowDescription, setWorkflowDescription] = useState("");
  const [showCustomCalendar, setShowCustomCalendar] = useState(false);
  const [sortColumn, setSortColumn] = useState("startTime");
  const [sortDirection, setSortDirection] = useState("desc");
  const [runs, setRuns] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    status: null,
    run_start: null,
    run_end: null,
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [workflowStructure, setWorkflowStructure] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);
  const [actualShowErrorPopup, setActualShowErrorPopup] = useState(false);
  const popupRef = useRef(null);

  const [selectedRunId, setSelectedRunId] = useState(null);
  const [showEmptyDrawerPopup, setShowEmptyDrawerPopup] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [openStartDropdown, setOpenStartDropdown] = useState(false);
  const [openEndDropdown, setOpenEndDropdown] = useState(false);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const [openVersionDropdown, setOpenVersionDropdown] = useState(false);

  const [runStartFilter, setRunStartFilter] = useState([null, null]);
  const [runEndFilter, setRunEndFilter] = useState([null, null]);
  const [runStartDisplay, setRunStartDisplay] = useState(null);
  const [runEndDisplay, setRunEndDisplay] = useState(null);
  const startDropdownRef = useRef(null);
  const endDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const [customDateType, setCustomDateType] = useState(null);
  const [copied, setCopied] = useState(false);
  const [total, setTotal] = useState(0);
  const [selectedNode, setSelectedNode] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const [hiddenNodes, setHiddenNodes] = useState(new Set());
  const [hiddenEdges, setHiddenEdges] = useState(new Set());

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [loadingStructure, setLoadingStructure] = useState(false);

  const [expandedRunIds, setExpandedRunIds] = useState(new Set());
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [observableDetails, setObservableDetails] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [highlightedNodeId, setHighlightedNodeId] = useState(null);
  const drawerRef = useRef(null);
  const [hiddenChildrenNodeIds, setHiddenChildrenNodeIds] = useState(new Set());
  const [isFlowReady, setIsFlowReady] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const observableListRef = useRef([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [childWorkflows, setChildWorkflows] = useState({});
  const [currentWorkflowName, setCurrentWorkflowName] = useState(null);
  const { workflowInstanceId } = useParams();
  useState(workflowInstanceId);
  const nodeWidth = 150;
  const nodeHeight = 50;
  const originalWarn = console.warn;
  const [llmSummary, setLlmSummary] = useState("");
  const [observableSummary, setObservableSummary] = useState("");
  const [visibleNodes, setVisibleNodes] = useState([]);
  const [visibleEdges, setVisibleEdges] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const { isObservableViewActive } = useObservableView(); // Use the context
  const [workflowInstanceCreatedAt, setWorkflowInstanceCreatedAt] =
    useState(null);
  const [lastClickedNodeIndex, setLastClickedNodeIndex] = useState(null);
  const combinedRuns = combineRuns(runs);
  const selectedRunData = combinedRuns.find((run) => run.id === selectedRunId);
  const selectedRunOutput = selectedRunData ? selectedRunData.outputs : [];

  const [initialHideDone, setInitialHideDone] = useState(false);
  const [isRunClick, setIsRunClick] = useState(false);

  const [shouldLoadData, setShouldLoadData] = useState(true);
  const [thisWorkflowId, setThisWorkflowId] = useState(null);
  const [showObservables, setShowObservables] = useState(false);
  const [searchByDisplay, setSearchByDisplay] = useState("Search by");
  const searchDropdownRef = useRef(null);
  const [selectedIdentifier, setSelectedIdentifier] = useState(null);
  const { fetchIdentifiers } = useApiUtils();
  const [identifiers, setIdentifiers] = useState([]);
  const [llmImages, setLlmImages] = useState([]);
  const [identifierName, setIdentifierName] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectedVersions, setSelectedVersions] = useState([]);
  const currentFiltersRef = useRef(activeFilters);

  const [fixedBottomHeight, setFixedBottomHeight] = useState(
    window.innerHeight
  );

  const updateFixedBottomHeight = () => {
    const drawerHeight =
      document.querySelector(".fixed-bottom")?.offsetHeight - 33 ||
      window.innerHeight;
    setFixedBottomHeight(drawerHeight);
  };

  useEffect(() => {
    updateFixedBottomHeight();
    window.addEventListener("resize", updateFixedBottomHeight);

    return () => {
      window.removeEventListener("resize", updateFixedBottomHeight);
    };
  }, []);

  console.warn = (message, ...args) => {
    if (
      typeof message === "string" &&
      message.includes("[React Flow]: Node type")
    ) {
      return;
    }
    originalWarn(message, ...args);
  };

  async function initializeData(thisWorkflowInstanceId, thisWorkflowName) {
    setIsOverlayVisible(true);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
    setCurrentPage(1);
    setIdentifierName(identifierName);
    setSearchTerm(searchTerm);
    setRuns([]);

    setCurrentWorkflowName(thisWorkflowName);

    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);

    try {
      // First fetch versions
      const fetchedVersions = await fetchWorkflowVersions(
        token,
        domain,
        workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId
      );
      setSelectedVersions(fetchedVersions);

      // Now fetch instances with the versions
      const allData = await fetchFilteredInstances(
        workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId,
        1,
        activeFilters,
        sortColumn,
        sortDirection,
        token,
        domain,
        identifierName,
        searchTerm,
        fetchedVersions
      );

      // Fetch identifiers if we have a workflow ID
      if (allData.response.data.workflow_id) {
        try {
          const identifiers = await fetchIdentifiers(
            token,
            domain,
            allData.response.data.workflow_id,
            workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId
          );
          setIdentifiers(identifiers);
        } catch (error) {
          console.error("Error loading identifiers:", error);
        }
      }

      console.log(
        "allData.response.data.total_count: ",
        allData.response.data.total_count
      );

      setTotal(allData.response.data.total_count || 0);
      setTotalPages(Math.ceil(allData.response.data.total_count / 25));
      setRuns(allData.processedInstances);
      setIsDrawerOpen(true);
      setIsLeftDrawerOpen(true);

      const idsList = allData.processedInstances.map((instance) => instance.id);

      if (allData.response.data.customer_id) {
        const thisCustomerId = allData.response.data.customer_id;
        const token = await getAccessTokenSilently();
        try {
          const newChildWorkflows = await fetchChildWorkflows(
            idsList,
            thisCustomerId,
            token,
            domain,
            workflowInstanceId
          );
          setChildWorkflows(newChildWorkflows);
        } catch (error) {
          console.error("Error fetching child workflows:", error);
        }

        if (allData.processedInstances) {
          thisWorkflowName = allData.response.data.workflow_name;
          const description = allData.response.data.workflow_description
            ? allData.response.data.workflow_description
            : "No description available";
          setWorkflowDescription(description);
          setCurrentWorkflowName(thisWorkflowName);
          if (
            workflowInstanceId &&
            thisWorkflowName &&
            allData.processedInstances.length > 0
          ) {
            updateBreadcrumbs(
              allData.response.data.workflow_id,
              thisWorkflowName,
              allData.processedInstances[0].id
            );
            setThisWorkflowId(allData.response.data.workflow_id);
          }
          setWorkflowInstanceCreatedAt(allData.processedInstances[0].startTime);
          const firstRunId = allData.processedInstances[0].id;
          setSelectedRunId(firstRunId);
          await processWorkflowStructure(firstRunId);
        } else {
          console.log("Customer ID is not set.");
        }
      }
    } catch (error) {
      console.error("Error in initializeData:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  }

  async function processWorkflowStructure(thisWorkflowInstanceId) {
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    try {
      const structureData = await fetchWorkflowStructure(
        thisWorkflowInstanceId,
        token,
        domain
      );
      if (structureData && structureData.length > 0) {
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );
        setObservableDetails(observableDetails);
        setHighlightedNodeId(structureData[0].id);
      } else {
        setWorkflowStructure(null);
        setObservableDetails([]);
        setHighlightedNodeId(null);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        console.error("Error fetching workflow structure:", error);
      }
      setWorkflowStructure(null);
      setObservableDetails([]);
      setHighlightedNodeId(null);
    }
  }

  useEffect(() => {
    if (!workflowStructure && !error) {
      const timer = setTimeout(() => {
        setActualShowErrorPopup(true);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [workflowStructure, error]);

  const handleDropdownClick = (nodeId) => {
    setHiddenChildrenNodeIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(nodeId)) {
        newIds.delete(nodeId);
      } else {
        newIds.add(nodeId);
      }
      return newIds;
    });
  };

  useEffect(() => {
    if (!workflowStructure) {
      console.log("No workflow structure found, EXITING EARLY");
      return; // Exit early if there's no workflow structure
    }

    const nodes = [];
    var edges = [];
    const positionMap = {};
    // Process workflow structure
    workflowStructure.forEach((item) => {
      const type = item.type || "default";
      let icon;

      // Icon mapping
      switch (type) {
        case "observable":
          icon = <FontAwesomeIcon className="node-icon" icon={faPenToSquare} />;
          break;
        case "llm_call":
          icon = <FontAwesomeIcon className="node-icon" icon={faComments} />;
          break;
        case "action":
          icon = <FontAwesomeIcon className="node-icon" icon={faGear} />;
          break;
        default:
          icon = null;
      }

      if (
        isObservableViewActive &&
        userIsQurrent &&
        (item.type === "action" || item.type === "llm_call") &&
        !item.trigger_action_id &&
        !item.llm_call_id &&
        !item.trigger_observable_id &&
        item.trigger_workflow_instance_id
      ) {
        // Add a placeholder observable node
        const placeholderObservableId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderObservableId,
          data: {
            label: "Placeholder Task",
          },
          type: "observable",
          position: { x: 250, y: 50 },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Add a placeholder agent node
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the placeholder observable
        edges.push({
          id: `e${placeholderAgentId}-${placeholderObservableId}`,
          source: placeholderAgentId,
          target: placeholderObservableId,
        });

        // Connect the placeholder observable to the current item
        edges.push({
          id: `e${placeholderObservableId}-${item.id}`,
          source: placeholderObservableId,
          target: item.id,
        });
      }

      // Placeholder agent node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      // Placeholder observable node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      const position = item.position || { x: 250, y: 0 };
      const timestamp = new Date(item.created_at).getTime();
      const baseY = positionMap[timestamp] || 0;

      const hasChildren = workflowStructure.some(
        (nextItem) =>
          nextItem.trigger_action_id === item.id ||
          nextItem.llm_call_id === item.id ||
          nextItem.trigger_observable_id === item.id
      );

      // Node creation
      nodes.push({
        id: item.id,
        data: {
          item,
          label: (
            <div
              id={item.id}
              className="node-label"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  overflow: "hidden",
                }}
              >
                {icon}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => {
                    setSelectedNode(item);
                  }}
                >
                  {type === "llm_call"
                    ? `LLM Call ...${item.id.slice(-5)}`
                    : type === "observable" || type === "action"
                    ? item.function_name
                    : "Node"}
                </span>
              </div>
              {type === "observable" && hasChildren && (
                <div style={{ marginLeft: "4px" }}>
                  <FontAwesomeIcon
                    icon={faAnglesDown}
                    className={`node-dropdown-icon ${
                      hiddenChildrenNodeIds.has(item.id) ? "rotated" : ""
                    }`}
                    style={{ fontSize: "0.8em" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDropdownClick(item.id);
                      toggleNodeVisibility(
                        item.id,
                        edges,
                        nodes,
                        hiddenNodes,
                        setHiddenNodes
                      );
                    }}
                  />
                </div>
              )}
            </div>
          ),
        },
        type: type,
        position: item.position,
        style: {
          backgroundColor: "var(--background-color-node)",
          borderColor: "var(--border-color)",
          borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
          borderStyle: "solid",
          borderRadius: "5px",
        },
      });

      positionMap[timestamp] = baseY + nodeHeight + 10;

      // Agent node
      if (item.trigger_console_agent_id && !item.trigger_observable_id) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`;
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatNodeName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: position.x - 100, y: position.y },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Agent node FROM ANOTHER WORKFLOW
      if (
        item.trigger_console_agent_id &&
        item.trigger_observable_id &&
        item.parent_workflow_instance_id
      ) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`; // Create a unique id for each agent
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatNodeName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: 250, y: 50 },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Action edge
      if (item.trigger_action_id) {
        edges.push({
          id: `e${item.trigger_action_id}-${item.id}`,
          source: item.trigger_action_id,
          target: item.id,
        });
      }

      // LLM call edge
      if (item.llm_call_id) {
        edges.push({
          id: `e${item.llm_call_id}-${item.id}`,
          source: item.llm_call_id,
          target: item.id,
        });
      }

      // Observable edge
      if (item.trigger_observable_id) {
        edges.push({
          id: `e${item.trigger_observable_id}-${item.id}`,
          source: item.trigger_observable_id,
          target: item.id,
        });
      }
    });

    // Initial hiding of child nodes for observables
    if (!initialHideDone) {
      workflowStructure.forEach((item) => {
        if (item.type === "observable") {
          toggleNodeVisibility(
            item.id,
            edges,
            nodes,
            hiddenNodes,
            setHiddenNodes
          );
        }
      });

      setInitialHideDone(true); // Mark initial hiding as done
    }

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      nodeWidth,
      nodeHeight,
      hiddenNodes,
      hiddenEdges
    );

    const updatedVisibleNodes = layoutedNodes.map((node) => ({
      ...node,
      hidden: hiddenNodes.has(node.id),
    }));

    const updatedVisibleEdges = layoutedEdges.map((edge) => ({
      ...edge,
      hidden: hiddenEdges.has(edge.id),
    }));

    setVisibleNodes(updatedVisibleNodes);
    setVisibleEdges(updatedVisibleEdges);

    if (updatedVisibleNodes.length > 0 && !initialHideDone) {
      const firstNode = updatedVisibleNodes[0];
      setTimeout(() => {
        setHighlightedNodeId(firstNode.id);
        setShowEmptyDrawerPopup(true);

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        setCenter(
          firstNode.position.x + viewportWidth / 5,
          firstNode.position.y + viewportHeight / 8,
          {
            zoom: 1.3,
            duration: 800,
          }
        );
        setInitialHideDone(true);
      }, 100);
    }

    const updatedObservableDetails = observableDetails.map((item) => {
      const visibleNode = updatedVisibleNodes.find(
        (node) => node.id === item.id
      );
      if (visibleNode) {
        const { data, ...restVisibleNode } = visibleNode;
        return { ...item, ...restVisibleNode };
      }
      return item;
    });

    setObservableDetails(updatedObservableDetails);

    // Cleanup function (if needed)
    return () => {
      // Perform any necessary cleanup here
    };
  }, [workflowStructure, hiddenNodes, hiddenEdges]);

  const renderWorkflowStructure = () => {
    return (
      <div
        className={`workflow-container ${
          isOverlayVisible ? "loading-overlay" : ""
        }`}
        ref={containerRef}
      >
        <ReactFlow
          nodes={visibleNodes.map((node) => ({
            ...node,
            className: node.id === selectedNode?.id ? "selected-node" : "",
          }))}
          edges={visibleEdges}
          onInit={handleFlowLoad}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          style={{ width: "100%", height: "100%" }}
          fitView
          className="reactflow-container"
          ref={containerRef}
          nodesDraggable={true}
          nodesConnectable={false}
          snapToGrid={true}
          snapGrid={[15, 15]}
          proOptions={{ hideAttribution: true }}
        >
          {loadingStructure && (
            <div className="loading-spinner-corner-workflow">
              <div className="loading-spinner"></div>
            </div>
          )}
          <Background />
        </ReactFlow>
      </div>
    );
  };

  const handlePaneClick = () => {
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true);
  };

  const handleFlowLoad = () => {
    setIsFlowReady(true);
  };

  const handleFilterToggle = async (type, value) => {
    let newFilters = { ...activeFilters };

    if (value === null) {
      // Handle filter removal
      if (type === "runStart") {
        delete newFilters.run_start;
        setRunStartFilter(null);
        setRunStartDisplay("");
      } else if (type === "runEnd") {
        delete newFilters.run_end;
        setRunEndFilter(null);
        setRunEndDisplay("");
      } else if (type === "status") {
        delete newFilters.status;
        setOpenStatusDropdown(null);
      }
    } else if (type === "status") {
      newFilters.status = value;
      setOpenStatusDropdown(null);
    } else if (type === "runStart" || type === "runEnd") {
      if (Array.isArray(value)) {
        const [start, end] = value;
        if (start && end) {
          if (type === "runStart") {
            setRunStartFilter([start, end]);
            setRunStartDisplay(
              `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
            );
            newFilters.run_start = [start, end];
          } else {
            setRunEndFilter([start, end]);
            setRunEndDisplay(
              `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
            );
            newFilters.run_end = [start, end];
          }
        }
      } else if (value === "Last 7 days" || value === "Last 30 days") {
        const end = new Date();
        const start = new Date();
        start.setDate(start.getDate() - (value === "Last 7 days" ? 7 : 30));

        const formattedDates = [start, end];

        if (type === "runStart") {
          setRunStartFilter(formattedDates);
          setRunStartDisplay(value);
          newFilters.run_start = formattedDates;
        } else {
          setRunEndFilter(formattedDates);
          setRunEndDisplay(value);
          newFilters.run_end = formattedDates;
        }
      }
    }

    setActiveFilters(newFilters);
    currentFiltersRef.current = newFilters;

    // Reset page and fetch data
    setCurrentPage(1);
    await fetchWorkflowData({
      filters: newFilters,
      page: 1,
    });
    setShowCustomCalendar(false);
  };

  const toggleDropdown = (filterType) => {
    if (filterType === "status") {
      setOpenStatusDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "runStart") {
      setOpenStartDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "runEnd") {
      setOpenEndDropdown((prev) => !prev);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "searchBy") {
      setOpenSearchDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenVersionDropdown(false);
    } else if (filterType === "version") {
      setOpenVersionDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
      setOpenSearchDropdown(false);
    }
  };

  const handleIdClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleClosePopup = () => {
    setSelectedNode(null);
    setIsDrawerOpen(false);
    setShowEmptyDrawerPopup(false);
  };

  const handleSortClick = async (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";

    setSortColumn(column); // Ensure the column is updated
    setSortDirection(newDirection); // Update the sort direction

    await fetchWorkflowData({
      newSort: column,
      newDirection,
      page: 1,
    });
  };

  const handlePageChange = async (newPage) => {
    console.log("Handle page change", newPage);
    await fetchWorkflowData({
      page: newPage,
      filters: currentFiltersRef.current,
      appendResults: true,
    });
  };

  const handleVersionSelect = async (versions) => {
    await fetchWorkflowData({
      page: 1,
      newSelectedVersions: versions,
    });
  };

  const handleSearch = async (identifier, searchTerm) => {
    if (!searchTerm) {
      setSelectedIdentifier(identifier);
      setSearchTerm("");
      return;
    }

    console.log("handleSearch", identifier, searchTerm);
    setSelectedIdentifier(identifier);
    await fetchWorkflowData({
      page: 1,
      newIdentifier: identifier.identifier_name,
      newSearchTerm: searchTerm,
    });
  };

  const clearSearch = async () => {
    setSearchTerm("");
    await fetchWorkflowData({
      page: 1,
      newIdentifier: null,
      newSearchTerm: null,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".App-filter-dropdown")) {
        setOpenStatusDropdown(false);
        setOpenStartDropdown(false);
        setOpenEndDropdown(false);
        setOpenSearchDropdown(false);
        setOpenVersionDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleExpand = (runId) => {
    setExpandedRunIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(runId)) {
        newIds.delete(runId);
      } else {
        newIds.add(runId);
      }
      return newIds;
    });
  };

  const updateBreadcrumbs = async (
    thisWorkflowId,
    thisWorkflowName,
    thisWorkflowInstanceId
  ) => {
    const theseBreadcrumbs = sessionStorage.getItem("breadcrumbs")
      ? JSON.parse(sessionStorage.getItem("breadcrumbs"))
      : [];

    const breadcrumbExists = theseBreadcrumbs.some(
      (crumb) => crumb.workflow_id === thisWorkflowId
    );

    if (!breadcrumbExists) {
      setCurrentWorkflowName(thisWorkflowName);

      const updatedBreadcrumbs = [
        ...theseBreadcrumbs,
        {
          workflow_id: thisWorkflowId,
          id: thisWorkflowInstanceId || null,
          name: thisWorkflowName,
        },
      ];

      console.log("UPDATED BREADCRUMBS", updatedBreadcrumbs);
      sessionStorage.setItem("breadcrumbs", JSON.stringify(updatedBreadcrumbs));
      setBreadcrumbs(updatedBreadcrumbs);
    }
  };

  // FOR CLICKING ON CHILD WORKFLOW
  const handleWorkflowClick = async (thisWorkflowInfo) => {
    console.log("handleWorkflowClick", thisWorkflowInfo);
    setWorkflowStructure(null);
    setHighlightedNodeId(null);
    setSelectedRunId(thisWorkflowInfo.id);
    setInitialHideDone(false);
    setIsOverlayVisible(true);

    try {
      const token = await getAccessTokenSilently();
      const domain = getDomainFromEmail(user.email);
      const structureData = await fetchWorkflowStructure(
        thisWorkflowInfo.id,
        token,
        domain
      );

      console.log("THIS WORKFLOW INFO", thisWorkflowInfo);

      if (structureData) {
        setWorkflowStructure(structureData);
        setHiddenNodes(initializeHiddenNodes(structureData));
        setCurrentWorkflowName(
          thisWorkflowInfo.display_name
            ? thisWorkflowInfo.display_name
            : thisWorkflowInfo.name
        );
        updateBreadcrumbs(
          thisWorkflowInfo.workflow_id,
          thisWorkflowInfo.display_name,
          thisWorkflowInfo.id
        );
        setThisWorkflowId(thisWorkflowInfo.workflow_id);

        setObservableDetails(
          structureData.filter((item) => item.type === "observable")
        );
        setSelectedNode(null);
        setHighlightedNodeId(structureData[0]?.id || null);
        setShouldLoadData(false);
      } else {
        console.warn(
          "Failed to fetch workflow structure for the selected run."
        );
      }
    } catch (error) {
      console.error("Error handling workflow click:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  // FOR CLICKING ON CHILD WORKFLOW LINK OR BREADCRUMB
  const handleWorkflowLinkClick = async (thisWorkflowInfo) => {
    console.log("handleWorkflowLinkClick", thisWorkflowInfo);
    setWorkflowStructure(null);
    setHighlightedNodeId(null);
    setSelectedRunId(thisWorkflowInfo.id);
    setInitialHideDone(false);
    setIsOverlayVisible(true);
    setShouldLoadData(false);

    navigate(`/workflow/${thisWorkflowInfo.id}`);

    try {
      const token = await getAccessTokenSilently();
      const domain = getDomainFromEmail(user.email);
      const structureData = await fetchWorkflowStructure(
        thisWorkflowInfo.id,
        token,
        domain
      );

      console.log("THIS WORKFLOW INFO", thisWorkflowInfo);

      if (structureData) {
        setWorkflowStructure(structureData);
        setHiddenNodes(initializeHiddenNodes(structureData));
        setCurrentWorkflowName(
          thisWorkflowInfo.display_name
            ? thisWorkflowInfo.display_name
            : thisWorkflowInfo.name
        );
        updateBreadcrumbs(
          thisWorkflowInfo.workflow_id,
          thisWorkflowInfo.display_name,
          thisWorkflowInfo.id
        );
        setThisWorkflowId(thisWorkflowInfo.workflow_id);

        setObservableDetails(
          structureData.filter((item) => item.type === "observable")
        );
        setSelectedNode(null);
        setHighlightedNodeId(structureData[0]?.id || null);
        setShouldLoadData(false);
      } else {
        console.warn(
          "Failed to fetch workflow structure for the selected run."
        );
      }
    } catch (error) {
      console.error("Error handling workflow click:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleRunClick = async (event, run) => {
    event.preventDefault();
    setInitialHideDone(false);

    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    setIsRunClick(true);

    if (
      event &&
      event.target &&
      event.target.closest(".see-more-button, .dropdown")
    ) {
      return;
    }

    setHighlightedNodeId(null);
    setLlmSummary("");
    setObservableSummary("");
    setSelectedRunId(run.id);
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true);

    navigate(`/workflow/${run.id}`);

    try {
      const structureData = await fetchWorkflowStructure(run.id, token, domain);
      if (structureData && structureData.length > 0) {
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );
        setObservableDetails(observableDetails);
        setHighlightedNodeId(structureData[0]?.id || null);
        setShowEmptyDrawerPopup(true);

        setHiddenNodes(initializeHiddenNodes(structureData));

        const workflowInfo = await fetchWorkflowInstanceInfo(
          run.id,
          token,
          domain
        );

        if (workflowInfo) {
          setCurrentWorkflowName(
            workflowInfo.display_name
              ? workflowInfo.display_name
              : workflowInfo.name
          );
          setThisWorkflowId(workflowInfo.workflow_id);
          const createdAt =
            run.startTime ||
            (workflowInfo.created_at
              ? new Date(workflowInfo.created_at)
              : null);
          setWorkflowInstanceCreatedAt(createdAt ? createdAt : "N/A");
        }
      }
    } catch (error) {
      console.error("Error fetching workflow structure:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleNodeClick = async (event, node) => {
    if (!node) {
      console.warn("Node is undefined:", node);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    if (selectedNode && selectedNode.id === node.id) {
      setSelectedNode(null);
      return;
    }

    setLlmSummary("");
    setLlmImages([]);
    setObservableSummary("");
    setSelectedNode(node);
    setIsDrawerOpen(true);
    centerAndZoomOnNode(node);

    try {
      const token = await getAccessTokenSilently();
      if (node.type === "llm_call") {
        await fetchLlmCallSummary(
          node.id,
          (chunk) => {
            if (typeof chunk === "object") {
              if (chunk.type === "images") {
                console.log("IMAGE", chunk);
                setLlmImages(chunk.data);
              } else if (chunk.type === "text") {
                setLlmSummary((prev) => prev + chunk.data);
              }
            } else {
              setLlmSummary((prev) => prev + chunk);
            }
          },
          token,
          abortControllerRef.current
        );
      } else if (node.type === "observable") {
        await fetchObservableSummary(
          node.id,
          (chunk) => {
            setObservableSummary((prev) => prev + chunk);
          },
          token,
          abortControllerRef.current
        );
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log(
          "Fetch request was aborted, which is expected when switching nodes quickly."
        );
      } else {
        console.error("Error fetching node summary:", err);
      }
    }
  };

  const centerAndZoomOnNode = (node) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (node && node.position) {
      const { x, y } = node.position;
      setCenter(x + viewportWidth / 5, y + viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    } else {
      setCenter(viewportWidth / 5, viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    }
  };

  useEffect(() => {
    const loadFullWorkflowData = async () => {
      if (!workflowInstanceId || !isFlowReady || !shouldLoadData) return;

      if (isRunClick) {
        setIsRunClick(false);
        return;
      }

      try {
        setIsOverlayVisible(true);
        setWorkflowStructure(null);
        setHighlightedNodeId(null);
        setSelectedRunId(null);
        setInitialHideDone(false);
        setCurrentWorkflowName(currentWorkflowName);
        setWorkflowInstanceCreatedAt(workflowInstanceCreatedAt);

        const token = await getAccessTokenSilently();
        const domain = getDomainFromEmail(user.email);

        let targetInstanceId = workflowInstanceId;
        if (!targetInstanceId) {
          const latestInstance = await fetchLatestWorkflowInstance(
            workflowInstanceId,
            token,
            domain
          );
          targetInstanceId = latestInstance.id;
        }

        await initializeData(workflowInstanceId, currentWorkflowName);
      } catch (error) {
        console.error("Error loading workflow data:", error);
      } finally {
        setIsOverlayVisible(false);
      }
    };

    loadFullWorkflowData();
  }, [isFlowReady]);

  const handleBackButtonClick = () => {
    sessionStorage.removeItem("breadcrumbs");
    setBreadcrumbs([]);
  };

  useEffect(() => {
    return () => {
      handleBackButtonClick();
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => {
          let newIndex = prevIndex + 1;
          while (newIndex < observableDetails.length) {
            if (observableDetails[newIndex].type !== "root") break;
            newIndex++;
          }
          // Wrap around to the top if at the end
          return newIndex >= observableDetails.length ? 0 : newIndex;
        });
      } else if (event.key === "ArrowUp") {
        setFocusedIndex((prevIndex) => {
          let newIndex = prevIndex - 1;
          while (newIndex >= 0) {
            if (observableDetails[newIndex].type !== "root") break;
            newIndex--;
          }
          // Wrap around to the bottom if at the start
          return newIndex < 0 ? observableDetails.length - 1 : newIndex;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [observableDetails]);

  useEffect(() => {
    if (observableListRef.current[focusedIndex]) {
      observableListRef.current[focusedIndex].focus();
      handleNodeClick(null, observableDetails[focusedIndex]);
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (lastClickedNodeIndex !== null) {
      setFocusedIndex(lastClickedNodeIndex);
    }
  }, [lastClickedNodeIndex]);

  const handleSeeMoreClickError = (errors) => {
    setErrorMessage(errors);
    setShowErrorPopup(true);
    setActualShowErrorPopup(false);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
      });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const initializeHiddenNodes = (workflowStructure) => {
    const newHiddenNodes = new Set();
    const newHiddenChildrenNodeIds = new Set(); // Track hidden children for icon state

    workflowStructure.forEach((node) => {
      if (node.type === "observable") {
        const childEdges = workflowStructure.filter(
          (n) => n.data?.item?.trigger_observable_id === node.id
        );

        childEdges.forEach((child) => {
          newHiddenNodes.add(child.id);
          newHiddenChildrenNodeIds.add(node.id); // Mark observable as having hidden children

          const findDescendants = (nodeId) => {
            workflowStructure.forEach((n) => {
              if (n.data?.item?.trigger_observable_id === nodeId) {
                newHiddenNodes.add(n.id);
                findDescendants(n.id);
              }
            });
          };

          findDescendants(child.id);
        });
      }
    });

    setHiddenChildrenNodeIds(newHiddenChildrenNodeIds); // Update state for icon control
    return newHiddenNodes;
  };

  const toggleActionAndLlmCalls = () => {
    setShowObservables((prev) => !prev);

    setWorkflowStructure((prevStructure) => {
      const newHiddenNodes = new Set(hiddenNodes);

      prevStructure.forEach((node) => {
        if (node.type === "action" || node.type === "llm_call") {
          if (showObservables) {
            newHiddenNodes.add(node.id);
          } else {
            newHiddenNodes.delete(node.id);
          }
        } else if (node.type === "observable" && node.trigger_observable_id) {
          if (showObservables) {
            newHiddenNodes.add(node.id);
          } else {
            newHiddenNodes.delete(node.id);
          }
        }
      });

      setHiddenNodes(newHiddenNodes);
      return prevStructure;
    });
  };

  useEffect(() => {
    if (workflowStructure) {
      setWorkflowStructure([...workflowStructure]);
    }
  }, [isObservableViewActive]);

  const fetchWorkflowData = async ({
    page = currentPage,
    filters = activeFilters,
    newSort = sortColumn,
    newDirection = sortDirection,
    newIdentifier = identifierName,
    newSearchTerm = searchTerm,
    appendResults = false,
    newSelectedVersions = selectedVersions,
  }) => {
    if (isLoading) return;

    setCurrentPage(page);
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);

    try {
      const { response, processedInstances } = await fetchFilteredInstances(
        workflowInstanceId,
        page,
        filters,
        newSort,
        newDirection,
        token,
        domain,
        newIdentifier,
        newSearchTerm,
        newSelectedVersions
      );

      if (response.data.workflow_id) {
        try {
          const identifiers = await fetchIdentifiers(
            token,
            domain,
            response.data.workflow_id,
            workflowInstanceId
          );
          setIdentifiers(identifiers);
        } catch (error) {
          console.error("Error loading identifiers:", error);
        }
      }

      setTotal(response.data.total_count || 0);
      setTotalPages(Math.ceil(response.data.total_count / 25));

      // Update runs while preserving existing data if appending
      setRuns((prevRuns) =>
        appendResults
          ? [...prevRuns, ...processedInstances]
          : processedInstances
      );

      // Get IDs from all instances (including existing ones if appending)
      const idsList = appendResults
        ? [...runs, ...processedInstances].map((instance) => instance.id)
        : processedInstances.map((instance) => instance.id);

      if (response.data.customer_id) {
        const thisCustomerId = response.data.customer_id;
        try {
          const newChildWorkflows = await fetchChildWorkflows(
            idsList,
            thisCustomerId,
            token,
            domain,
            workflowInstanceId
          );

          // Merge with existing child workflows if appending
          setChildWorkflows((prevChildWorkflows) =>
            appendResults
              ? { ...prevChildWorkflows, ...newChildWorkflows }
              : newChildWorkflows
          );
        } catch (error) {
          console.error("Error fetching child workflows:", error);
        }
      }

      // Update other state
      setCurrentPage(page);
      setSortColumn(newSort);
      setSortDirection(newDirection);
      setIdentifierName(newIdentifier);
      setSearchTerm(newSearchTerm);
      setSelectedVersions(newSelectedVersions);
      if (filters !== activeFilters) {
        setActiveFilters(filters);
      }

      return { response, processedInstances };
    } catch (error) {
      console.error("Error fetching workflow data:", error);
      if (!appendResults) setRuns([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="WorkflowDetails" ref={containerRef}>
      <Header userIsQurrent={userIsQurrent} />
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        currentWorkflowInstanceId={workflowInstanceId}
        handleBackButtonClick={handleBackButtonClick}
        handleWorkflowClick={handleWorkflowLinkClick}
        thisWorkflowId={thisWorkflowId}
      />
      {workflowStructure &&
        workflowStructure.some(
          (node) => node.type === "action" || node.type === "llm_call"
        ) && (
          <button
            className={`top-right-filter-button ${
              isDrawerOpen ? "drawer-open" : ""
            }`}
            onClick={toggleActionAndLlmCalls}
          >
            {showObservables ? "Collapse" : "Expand"}
          </button>
        )}
      <button className="drawer-toggle-button" onClick={toggleLeftDrawer}>
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
      <LeftDrawer
        toggleLeftDrawer={toggleLeftDrawer}
        handleNodeClick={handleNodeClick}
        formatNodeName={formatNodeName}
        isLeftDrawerOpen={isLeftDrawerOpen}
        workflowDescription={workflowDescription}
        observableDetails={observableDetails}
        selectedNode={selectedNode}
        observableListRef={observableListRef}
        fixedBottomHeight={fixedBottomHeight}
      />
      <div className="popup-drawer" onClick={toggleDrawer}>
        <FontAwesomeIcon icon={faAnglesLeft} className="right-angles" />
      </div>
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`} ref={drawerRef}>
        {selectedNode ? (
          <div>
            {(() => {
              switch (selectedNode.type) {
                case "action":
                  return (
                    <ActionNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                case "llm_call":
                  return (
                    <LLMCallNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                      llmSummary={llmSummary}
                      llmImages={llmImages}
                    />
                  );
                case "observable":
                  return (
                    <ObservableNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                      observableSummary={observableSummary}
                    />
                  );
                case "agent":
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                default:
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
              }
            })()}
          </div>
        ) : (
          <div>
            {(!selectedNode || showEmptyDrawerPopup) && (
              <EmptyDrawerMessagePopup
                currentWorkflowName={currentWorkflowName}
                workflowDescription={workflowDescription}
                onClose={handleClosePopup}
                outputs={selectedRunOutput}
                workflowInstanceId={workflowInstanceId}
                workflowInstanceCreatedAt={workflowInstanceCreatedAt}
                copied={copied}
                handleIdClick={handleIdClick}
              />
            )}
          </div>
        )}
      </div>
      {renderWorkflowStructure()}
      <WorkflowTable
        handleSortClick={handleSortClick}
        getSortIcon={getSortIcon}
        handleFilterToggle={handleFilterToggle}
        toggleDropdown={toggleDropdown}
        setRunStartFilter={setRunStartFilter}
        setRunStartDisplay={setRunStartDisplay}
        setRunEndFilter={setRunEndFilter}
        setRunEndDisplay={setRunEndDisplay}
        setActiveFilters={setActiveFilters}
        handleSeeMoreClickError={handleSeeMoreClickError}
        setShowCustomCalendar={setShowCustomCalendar}
        handleWorkflowClick={handleWorkflowClick}
        handleRunClick={handleRunClick}
        handleWorkflowLinkClick={handleWorkflowLinkClick}
        toggleExpand={toggleExpand}
        getStatusIcon={getStatusIcon}
        handlePageChange={handlePageChange}
        setFixedBottomHeight={setFixedBottomHeight}
        setSearchByDisplay={setSearchByDisplay}
        setFocusedIndex={setFocusedIndex}
        setOpenSearchDropdown={setOpenSearchDropdown}
        handleSearch={handleSearch}
        setSelectedIdentifier={setSelectedIdentifier}
        clearSearch={clearSearch}
        setCustomDateType={setCustomDateType}
        handleVersionSelect={handleVersionSelect}
        combinedRuns={combinedRuns}
        runStartDisplay={runStartDisplay}
        runEndDisplay={runEndDisplay}
        openStartDropdown={openStartDropdown}
        openEndDropdown={openEndDropdown}
        openStatusDropdown={openStatusDropdown}
        activeFilters={activeFilters}
        runStartFilter={runStartFilter}
        runEndFilter={runEndFilter}
        total={total}
        isLoading={isLoading}
        isDrawerOpen={isDrawerOpen}
        drawerRef={drawerRef}
        showCustomCalendar={showCustomCalendar}
        dateRange={dateRange}
        statusDropdownRef={statusDropdownRef}
        startDropdownRef={startDropdownRef}
        endDropdownRef={endDropdownRef}
        selectedRunId={selectedRunId}
        childWorkflows={childWorkflows}
        expandedRunIds={expandedRunIds}
        totalPages={totalPages}
        currentPage={currentPage}
        searchByDisplay={searchByDisplay}
        searchDropdownRef={searchDropdownRef}
        focusedIndex={focusedIndex}
        openSearchDropdown={openSearchDropdown}
        openVersionDropdown={openVersionDropdown}
        selectedIdentifier={selectedIdentifier}
        selectedVersions={selectedVersions}
        setSelectedVersions={setSelectedVersions}
        identifiers={identifiers}
        customDateType={customDateType}
        workflowInstanceId={workflowInstanceId}
      />
      {showErrorPopup && (
        <ErrorPopup
          errorMessage={errorMessage}
          onClose={() => setShowErrorPopup(false)}
        />
      )}
      {actualShowErrorPopup && (
        <ActualErrorPopup
          errorMessage={errorMessage}
          onClose={() => setActualShowErrorPopup(false)}
        />
      )}
    </div>
  );
}

export default WorkflowDetails;
