/* eslint-disable */
import config from "../config.js";
import axios from "axios";

export const initiateAuth0Login = (loginWithRedirect) => {
  loginWithRedirect({
    appState: { returnTo: window.location.origin },
    scope: "openid profile email",
    prompt: "select_account login",
    connection: undefined,
    max_age: 0,
    ui_locales: "en",
  });
};

export const handleLogin = async ({
  user,
  config,
  getAccessTokenSilently,
  navigate,
}) => {
  try {
    const token = await getAccessTokenSilently({
      audience: config.audience,
      prompt: "select_account login",
    });

    const loginResponse = await axios.post(
      `${config.apiOrigin}/users/login`,
      {
        email: user.email,
        name: user.name || user.email.split("@")[0],
        ip_address: window.location.hostname,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    return loginResponse.data;
  } catch (error) {
    console.error("Error during login or session logging:", error);
    if (error.response) {
      if (error.response.status === 403) {
        navigate("/error", {
          state: {
            title: "Unauthorized Email",
            message:
              "Your email was not authorized. Please use your work email.",
            contactInfo: "If the problem persists, contact team@qurrent.ai",
          },
        });
      } else if (error.response.status === 500) {
        navigate("/error", {
          state: {
            title: "Internal Server Error",
            message: "An internal server error occurred.",
            contactInfo: "If the problem persists, contact team@qurrent.ai",
          },
        });
      } else {
        navigate("/error", {
          state: {
            title: "Unknown Error",
            message: "An unknown error occurred.",
            contactInfo: "If the problem persists, contact team@qurrent.ai",
          },
        });
      }
    }
    throw error;
  }
};

export const handleLogout = async ({
  logout,
  getAccessTokenSilently,
  apiUtils,
}) => {
  const sessionId = sessionStorage.getItem("session_id");
  const auth0Domain = config.auth0Domain;

  try {
    // Clear all application state first
    sessionStorage.clear();

    // Clear any application-specific cookies
    document.cookie.split(";").forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, "")
        .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`);
    });

    // End backend session if exists
    if (sessionId) {
      try {
        const token = await getAccessTokenSilently({
          audience: config.audience,
          prompt: "select_account login",
        });
        await apiUtils.endSession(token, sessionId);
      } catch (error) {
        console.error("Error ending backend session:", error);
        // Continue with logout even if backend session end fails
      }
    }

    // Finally, logout from Auth0
    await logout({
      returnTo: window.location.origin,
      federated: true,
      localOnly: false,
      end_session_endpoint: `https://${auth0Domain}/v2/logout?federated&returnTo=${encodeURIComponent(
        window.location.origin
      )}&client_id=${config.clientId}`,
    });
  } catch (error) {
    console.error("Error during logout:", error);
    // Force reload as fallback to ensure user is logged out
    window.location.href = window.location.origin;
  }
};
