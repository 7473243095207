import React from "react";
import Header from "./Header.js";
function Metrics({ userIsQurrent }) {
  return (
    <div>
      <Header userIsQurrent={userIsQurrent} />
      <div className="App-main">
        <h1>Metrics</h1>
      </div>
    </div>
  );
}

export default Metrics;
