import Header from "./Header.js";
import "react-calendar/dist/Calendar.css";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSuperdupont } from "./contexts/SuperdupontContext.js";
import EnvSwitcher from "./EnvSwitcher.js";
import MainTable from "./main_components/MainTable.js";
import Pagination from "./main_components/Pagination.js";
import ErrorNotification from "./main_components/ErrorNotification.js";
import Cards from "./main_components/Cards.js";
import FilterRow from "./main_components/FilterRow.js";
import { useFilter } from "./contexts/FilterContext.js";

import {
  getSortIcon,
  formatDateTime,
  getDomainFromEmail,
} from "./utils/formatUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";

import config from "./config.js";

function MainContent({ userIsQurrent }) {
  const {
    fetchWorkflowInstances,
    searchWorkflows,
    fetchCustomers,
    fetchTotalRuns,
  } = useApiUtils();

  const navigate = useNavigate();
  const { getAccessTokenSilently, user } = useAuth0();
  const { isSuperdupont } = useSuperdupont();
  const { filters, setFilters } = useFilter(); // Use the filter context

  const [showCalendar, setShowCalendar] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    status: null,
    name: null,
    lastRun: null,
  });

  const [selectedDateRange, setSelectedDateRange] = useState([
    new Date(),
    new Date(),
  ]);

  const submitButtonRef = useRef(null);
  const calendarRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);

  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [warningCount, setWarningCount] = useState(0);
  const [totalWorkflows, setTotalWorkflows] = useState(0);
  const [totalRuns, setTotalRuns] = useState("Calculating Runs...");

  const [sortColumn, setSortColumn] = useState("last_run");
  const [sortDirection, setSortDirection] = useState("desc");
  const [statusSortIndex, setStatusSortIndex] = useState(0);
  const statusOrder = ["created", "completed", "failed"];

  const [openDropdown, setOpenDropdown] = useState(null);

  const [stateIsLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);

  const [spinningColumn, setSpinningColumn] = useState(null);

  const [pageInput, setPageInput] = useState(currentPage);
  const [customers, setCustomers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const resetToFirstPage = () => {
    setCurrentPage(1);
  };

  const toggleFilterOptions = (filterType) => {
    console.log("Toggling filter options for:", filterType);
    setOpenDropdown((prev) => (prev === filterType ? null : filterType));
  };

  const handleSortClick = (column) => {
    if (spinningColumn !== column) {
      setSpinningColumn(column);

      if (column === "latest_status") {
        setStatusSortIndex((prevIndex) => (prevIndex + 1) % statusOrder.length);
        setSortColumn(column);
        setSortDirection(statusOrder[statusSortIndex]);
      } else {
        if (sortColumn === column) {
          setSortDirection((prevDirection) =>
            prevDirection === "asc" ? "desc" : "asc"
          );
        } else {
          setSortColumn(column);
          setSortDirection("asc");
        }
      }
      setCurrentPage(1);

      setTimeout(() => {
        setSpinningColumn(null);
      }, 200);
    }
  };

  const fetchWorkflowInstancesCallback = useCallback(
    async (
      column = sortColumn,
      direction = sortDirection,
      page = currentPage
    ) => {
      setIsLoading(true);
      setError(null);

      try {
        const token = await getAccessTokenSilently({
          audience: config.auth0Audience,
        });
        const domain = getDomainFromEmail(user.email);

        if (!isSuperdupont) {
          filters.customer = null;
        }

        const data = await fetchWorkflowInstances({
          token,
          domain,
          column,
          direction,
          activeFilters: filters, // Use filters from context
          showPreviousVersions: filters.showPreviousVersions, // Use from context
          isSuperdupont,
          selectedCustomer: filters.customer, // Use from context
          page,
        });

        console.log("Fetched workflows:", data);

        const newData = data.data || [];
        setWorkflows(newData);
        setFilteredWorkflows(newData);

        const totalActiveWorkflows = data.total || 1;
        const itemsPerPage = 20;
        const calculatedTotalPages = Math.ceil(
          totalActiveWorkflows / itemsPerPage
        );
        setTotalPages(calculatedTotalPages);

        if (
          !activeFilters.status &&
          !activeFilters.name &&
          !activeFilters.lastRun
        ) {
          setTotalWorkflows(data.total || 0);
          setErrorCount(data.latest_failed_count || 0);
          setSuccessCount(data.latest_success_count || 0);
          setWarningCount(data.latest_warning_count || 0);
        }
      } catch (err) {
        console.error("Error fetching workflow instances:", err);
        setError("Failed to load workflow data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    },
    [
      getAccessTokenSilently,
      user.email,
      sortColumn,
      sortDirection,
      filters,
      isSuperdupont,
      currentPage,
    ]
  );

  useEffect(() => {
    const intervalId = setInterval(
      () => fetchWorkflowInstancesCallback(),
      30000
    );
    fetchWorkflowInstancesCallback();

    return () => clearInterval(intervalId);
  }, [fetchWorkflowInstancesCallback]);

  const handleChange = (value) => {
    setSelectedDateRange(value);
  };

  const submitDateRange = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      let [start, end] = selectedDateRange;

      const dateRangeFilter = {
        start: start,
        end: end,
      };

      setFilters((prev) => ({ ...prev, lastRun: dateRangeFilter }));
      setShowCalendar(false);
      setOpenDropdown(null);
    }
  };

  const removeFilter = (index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const handleNameSearch = async (searchTerm) => {
    const minSearchLength = 3; // Set the minimum number of characters

    if (Array.isArray(workflows)) {
      console.log("Workflows is an array with length:", workflows.length);
    } else if (typeof workflows === "object") {
      console.log("Workflows is an object with keys:", Object.keys(workflows));
    }

    if (searchTerm.length >= minSearchLength) {
      try {
        const token = await getAccessTokenSilently();
        const matchedWorkflows = await searchWorkflows(
          searchTerm,
          workflows,
          token
        );
        setFilteredWorkflows(matchedWorkflows);
      } catch (error) {
        console.error("Error searching workflows:", error);
      }
    } else {
      setFilteredWorkflows(workflows); // Reset to all workflows if search term is too short
    }
  };

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      handleNameSearch(searchTerm);
      setFilters((prev) => ({ ...prev, name: searchTerm })); // Update context
      setOpenDropdown(null); // Close the dropdown after search
      resetToFirstPage(); // Reset to first page when a filter is applied
    }
  };

  const handleNameKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default Enter behavior
      const searchTerm = e.target.value.trim();
      handleSearch(searchTerm);
    }
  };

  const handleDateFilter = (option) => {
    if (option === "Custom") {
      setSelectedDateRange([new Date(), new Date()]); // Reset the date range
      setShowCalendar(true);
      setOpenDropdown(null); // Close the dropdown when "Custom" is selected
    } else {
      let startDate, endDate;
      const now = new Date();

      if (option === "Last 7 Days") {
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 7);
        endDate = now;
      } else if (option === "Last 30 Days") {
        startDate = new Date(now);
        startDate.setDate(now.getDate() - 30);
        endDate = now;
      }

      if (startDate && endDate) {
        const dateRangeFilter = {
          start: startDate,
          end: endDate,
        };

        console.log("Date Range Filter:", dateRangeFilter); // Debugging: Check the date range

        setFilters((prev) => ({ ...prev, lastRun: dateRangeFilter }));
        setOpenDropdown(null);
        resetToFirstPage();
      }
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    // Only add the event listener if the calendar is shown
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  useEffect(() => {
    setPageInput(currentPage); // Sync pageInput with currentPage
  }, [currentPage]);

  const handleRowClick = (item) => {
    navigate(`/workflow/${item.first_workflow_instance_id}`);
  };

  const toggleStatusFilter = (status) => {
    setFilters((prev) => ({
      ...prev,
      status: prev.status === status ? null : status,
    })); // Update context
    setOpenDropdown(null); // Close the dropdown after selection
  };

  const handleStatusFilter = (status) => {
    setFilters((prev) => ({ ...prev, status })); // Update context
    setOpenDropdown(null); // Close the dropdown after selection
    resetToFirstPage(); // Reset to first page when a filter is applied
  };

  const handleCheckboxChange = () => {
    setFilters((prev) => ({
      ...prev,
      showPreviousVersions: !prev.showPreviousVersions,
    })); // Update context
    setCurrentPage(1);
  };

  const handlePageInputChange = (e) => {
    setPageInput(e.target.value); // Allow any input
  };

  const handlePageInputSubmit = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber); // Update currentPage to the new page number
    } else {
      setPageInput(currentPage); // Reset to current page if invalid
    }
  };

  useEffect(() => {
    const fetchAndSetCustomers = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: config.auth0Audience,
        });
        const domain = getDomainFromEmail(user.email);
        const response = await fetchCustomers(token, domain);
        setCustomers(response);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }
    };

    fetchAndSetCustomers();
  }, []);

  const filterWorkflowsByCustomer = useCallback(
    (customerName) => {
      setFilters((prev) => ({ ...prev, customer: customerName }));
      setCurrentPage(1); // Reset to first page when changing customer filter
    },
    [setFilters]
  );

  useEffect(() => {
    const fetchTotalRunsData = async () => {
      try {
        const domain = getDomainFromEmail(user.email);
        const token = await getAccessTokenSilently({
          audience: config.auth0Audience,
        });
        const fetchedTotalRuns = await fetchTotalRuns(
          domain,
          isSuperdupont,
          token
        );

        // Only update the state if the fetched data is different and valid
        setTotalRuns((currentTotalRuns) => {
          if (
            typeof fetchedTotalRuns === "number" &&
            currentTotalRuns !== fetchedTotalRuns
          ) {
            return fetchedTotalRuns;
          }
          return currentTotalRuns; // Keep the current value if no update is needed
        });
      } catch (error) {
        console.error("Error fetching total runs:", error);
      }
    };

    fetchTotalRunsData();
  }, [isSuperdupont]);

  return (
    <main className="App-main">
      <Header isSuperdupont={isSuperdupont} userIsQurrent={userIsQurrent} />
      <Cards
        totalWorkflows={totalWorkflows}
        totalRuns={totalRuns}
        errorCount={errorCount}
        warningCount={warningCount}
        successCount={successCount}
        toggleStatusFilter={(status) => {
          toggleStatusFilter(status);
          resetToFirstPage(); // Reset to first page when a filter is toggled
        }}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        currentDate={new Date().toLocaleDateString()}
        error={error}
        fetchWorkflowInstances={fetchWorkflowInstancesCallback}
      />
      <ErrorNotification
        error={error}
        fetchWorkflowInstances={fetchWorkflowInstancesCallback}
      />
      <FilterRow
        activeFilters={filters}
        toggleFilterOptions={toggleFilterOptions}
        handleStatusFilter={handleStatusFilter}
        setActiveFilters={setFilters}
        openDropdown={openDropdown}
        handleNameKeyPress={handleNameKeyPress}
        handleSearch={handleSearch}
        handleDateFilter={handleDateFilter}
        customers={customers}
        filterWorkflowsByCustomer={filterWorkflowsByCustomer}
        selectedCustomer={filters.customer}
        setSelectedCustomer={(customer) =>
          setFilters((prev) => ({ ...prev, customer }))
        }
        filters={filters}
        removeFilter={removeFilter}
        showCalendar={showCalendar}
        calendarRef={calendarRef}
        handleChange={handleChange}
        selectedDateRange={selectedDateRange}
        submitDateRange={submitDateRange}
        submitButtonRef={submitButtonRef}
        isSuperdupont={isSuperdupont}
        EnvSwitcher={EnvSwitcher}
        fetchWorkflowInstances={fetchWorkflowInstancesCallback}
      />
      <MainTable
        workflows={workflows}
        handleSortClick={handleSortClick}
        getSortIcon={getSortIcon}
        handleRowClick={handleRowClick}
        formatDateTime={formatDateTime}
        showPreviousVersions={filters.showPreviousVersions}
        handleCheckboxChange={handleCheckboxChange}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        pageInput={pageInput}
        handlePageInputChange={handlePageInputChange}
        handlePageInputSubmit={handlePageInputSubmit}
        goToFirstPage={goToFirstPage}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        goToLastPage={goToLastPage}
        disableNext={currentPage === totalPages} // Add this line
      />
      {stateIsLoading && (
        <div className="loading-spinner-corner">
          <div className="loading-spinner"></div>
        </div>
      )}
    </main>
  );
}

export default MainContent;
