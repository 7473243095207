import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Auth0Auth from "./Auth0Auth.js";
import MainContent from "./MainContent.js";
import WorkflowDetails from "./WorkflowDetails.js";
import { useAuth0 } from "@auth0/auth0-react";
import AutoLogout from "./AutoLogout.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { useUser } from "./contexts/UserContext.js";
import { getConfig } from "./config.js";
import NotAuthorized from "./NotAuthorized.js";
import ErrorOccuredPage from "./ErrorOccuredPage.js";
import { handleLogin, handleLogout } from "./utils/authUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";
import AuthenticationScreen from "./AuthenticationScreen.js";
import Metrics from "./Metrics.js";

function AppContent() {
  const {
    isLoading: auth0Loading,
    isAuthenticated,
    user,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const apiUtils = useApiUtils();
  const navigate = useNavigate();
  const [loginAttempted, setLoginAttempted] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [showAuthScreen, setShowAuthScreen] = useState(true);

  const { env } = useContext(EnvironmentContext);
  const config = getConfig(env);

  const { setUserIsQurrent } = useUser();
  const [userIsQurrent, setUserIsQurrentState] = useState(false);

  const applySavedTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  };

  useEffect(() => {
    applySavedTheme();
  }, []);

  useEffect(() => {
    const processUserLogin = async () => {
      if (isAuthenticated && user && !loginAttempted) {
        setLoginAttempted(true);
        setIsAuthenticating(true);
        try {
          const loginData = await handleLogin({
            user,
            config,
            getAccessTokenSilently,
            navigate,
          });

          if (loginData.success) {
            console.log("USER IS QURRENT: ", loginData.user_is_qurrent);
            sessionStorage.setItem("session_id", loginData.session_id);
            setUserIsQurrent(loginData.user_is_qurrent);
            setUserIsQurrentState(loginData.user_is_qurrent);
          }
        } catch (error) {
          return;
        } finally {
          setIsAuthenticating(false);
        }
      } else if (!isAuthenticated) {
        setLoginAttempted(false);
        setIsAuthenticating(false);
        console.log("Auth0 state:", {
          auth0Loading,
          isAuthenticated,
          user: user,
          error,
        });
        console.log(
          "User is not authenticated or user object is not available"
        );
      }
    };

    processUserLogin();
  }, [
    isAuthenticated,
    user,
    getAccessTokenSilently,
    auth0Loading,
    error,
    config,
    navigate,
    loginAttempted,
  ]);

  useEffect(() => {
    if (!auth0Loading && !isAuthenticating) {
      setShowAuthScreen(false);
    }
  }, [auth0Loading, isAuthenticating]);

  if (error) {
    return (
      <ErrorOccuredPage
        title="Authentication Error"
        message={error.message}
        contactInfo="Please try again or contact support if the issue persists."
        onReturn={() => {
          setLoginAttempted(false);
          handleLogout({ logout, getAccessTokenSilently, apiUtils });
        }}
      />
    );
  }

  if (showAuthScreen) {
    return <AuthenticationScreen />;
  }

  return (
    <div className="App">
      <AutoLogout />
      {isAuthenticated ? (
        <Routes>
          <Route
            path="/"
            element={<MainContent user={user} userIsQurrent={userIsQurrent} />}
          />
          <Route
            path="/workflow/:workflowInstanceId"
            element={<WorkflowDetails userIsQurrent={userIsQurrent} />}
          />
          <Route
            path="/metrics"
            element={<Metrics userIsQurrent={userIsQurrent} />}
          />
          <Route path="/not-authorized" element={<NotAuthorized />} />
          <Route path="/error" element={<ErrorOccuredPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/login" element={<Auth0Auth />} />
          <Route path="/error" element={<ErrorOccuredPage />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </div>
  );
}

export default AppContent;
