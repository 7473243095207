import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { initiateAuth0Login, handleLogout } from "./utils/authUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";

const Auth0Auth = () => {
  const { loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [errorMessage, setErrorMessage] = useState("");
  const apiUtils = useApiUtils();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("error")) {
      setErrorMessage(params.get("error_description") || "An error occurred.");
    }
  }, []);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="company-logo" aria-label="Company Logo"></div>
        <h1 className="welcome-message">Welcome to Qurrent!</h1>
        {errorMessage && (
          <div className="error-banner">
            <p>{errorMessage}</p>
            <button
              onClick={() =>
                handleLogout({ logout, getAccessTokenSilently, apiUtils })
              }
              className="logout-button"
            >
              Log Out
            </button>
          </div>
        )}
        <button
          onClick={() => initiateAuth0Login(loginWithRedirect)}
          className="login-button"
        >
          Log In
        </button>
      </div>
    </div>
  );
};

export default Auth0Auth;
