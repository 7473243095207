/* eslint-disable */
import React from "react";
import qurrentLogoDark from "./assets/qurrent_icon.png";
import "./App.css";

const AuthenticationScreen = () => {
  return (
    <div className="authentication-container">
      <div className="loading-content">
        <img
          src={qurrentLogoDark}
          alt="Qurrent Logo"
          className="authentication-logo"
        />
        <h1>Authenticating...</h1>
        <div className="authentication-spinner"></div>
      </div>
    </div>
  );
};

export default AuthenticationScreen;
