import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function FilterDropdown({
  type,
  displayValue,
  isActive,
  onClear,
  toggleFilterOptions,
  openDropdown,
  children,
  className = "",
}) {
  const handleClear = (e) => {
    e.stopPropagation();
    onClear();
  };

  return (
    <div
      className={`App-filter-dropdown ${className}`}
      onClick={() => toggleFilterOptions(type)}
    >
      <button className={`App-filter-button ${isActive ? "active" : ""}`}>
        {displayValue}{" "}
        <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
      </button>
      {isActive && (
        <button className="App-filter-clear" onClick={handleClear}>
          ×
        </button>
      )}
      {openDropdown === type && (
        <div
          className="App-filter-options-workflows"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      )}
    </div>
  );
}

export default FilterDropdown;
