import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faClone } from "@fortawesome/free-solid-svg-icons";
import { formatCost } from "../utils/formatUtils.js";
import { createPortal } from "react-dom";
import { formatId, formatDate } from "../utils/formatUtils.js";

const LLMCallNodePopup = ({
  node,
  handleClosePopup,
  copied,
  handleIdClick,
  llmSummary,
  llmImages,
  loadingSummary,
}) => {
  const nodeData = node.data?.item || node;
  const [selectedImage, setSelectedImage] = useState(null);

  const renderImages = () => {
    if (!llmImages || !Array.isArray(llmImages)) {
      console.log("No valid images array");
      return null;
    }

    return (
      <div className="summary-images">
        {llmImages.map((image, index) => (
          <img
            key={index}
            src={image.thumbnail}
            alt={`Summary thumbnail ${index}`}
            className="summary-thumbnail"
            onClick={() => {
              const fullImage = image.original || image.thumbnail;
              setSelectedImage(fullImage);
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="popup-overlay">
        <div className="popup-content">
          <div className="main-row">
            <FontAwesomeIcon className="node-icon-popup" icon={faComments} />
            <div className="title-row">
              <div className="title-header">
                {nodeData.llm_name || "LLM Call"}
              </div>
              <button
                className="close-button-right-drawer"
                onClick={handleClosePopup}
              >
                &times;
              </button>
            </div>
          </div>
          <hr className="divider-popup" />
          {Object.entries(nodeData)
            .filter(([key]) => ["id", "created_at", "model_name"].includes(key))
            .map(([key, value]) => {
              const isIdField = key.toLowerCase() === "id";
              const displayValue =
                isIdField && value !== "None"
                  ? formatId(value)
                  : value === null || value === ""
                  ? "N/A"
                  : value;

              return (
                <div key={key}>
                  <p className="key-value-pair">
                    <strong>
                      {key === "created_at"
                        ? "START"
                        : key === "model_name"
                        ? "MODEL"
                        : key.toUpperCase()}
                    </strong>
                    <span
                      className={
                        isIdField && value !== "None" && displayValue !== "N/A"
                          ? "id-tooltip"
                          : ""
                      }
                      title={
                        isIdField && value !== "None" && displayValue !== "N/A"
                          ? value
                          : ""
                      }
                      onClick={() => {
                        if (
                          isIdField &&
                          value !== "None" &&
                          displayValue !== "N/A"
                        ) {
                          handleIdClick(value);
                        }
                      }}
                      style={{ cursor: isIdField ? "pointer" : "default" }}
                    >
                      {isIdField &&
                        value !== "None" &&
                        displayValue !== "N/A" && (
                          <span
                            onClick={() => handleIdClick(value)}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                              position: "relative",
                            }}
                          >
                            {copied && (
                              <span className="copied-style">Copied!</span>
                            )}

                            <FontAwesomeIcon
                              className="copy-icon"
                              icon={faClone}
                            />
                          </span>
                        )}
                      {key === "created_at"
                        ? formatDate(displayValue)
                        : key === "cost"
                        ? formatCost(displayValue)
                        : typeof displayValue === "object"
                        ? JSON.stringify(displayValue, null, 2)
                        : displayValue}
                    </span>
                  </p>
                </div>
              );
            })}
          <hr className="divider-popup" />
          <strong className="description-content">OUTPUT</strong>
          <div className="formatted-content">
            <p>{nodeData.output}</p>
          </div>
          <strong className="description-content">INPUT SUMMARY</strong>
          <div className="formatted-content summary-with-images">
            {loadingSummary ? (
              <p>Loading summary...</p>
            ) : (
              <>
                <div className="summary-text">
                  <p>{llmSummary}</p>
                </div>
                {renderImages()}
              </>
            )}
          </div>
        </div>
      </div>
      {selectedImage &&
        createPortal(
          <div
            className="image-modal-overlay"
            onClick={() => setSelectedImage(null)}
          >
            <img
              src={selectedImage}
              alt="Full size"
              onClick={(e) => e.stopPropagation()}
              className="image-modal"
            />
          </div>,
          document.body
        )}
    </>
  );
};

export default LLMCallNodePopup;
