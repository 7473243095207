import React from "react";

function ErrorNotification({ error, fetchWorkflowInstances }) {
  if (!error) return null;

  return (
    <div className="error-notification-overlay">
      <div className="error-notification">
        <div className="error-content">
          <h2>Oops! Something went wrong</h2>
          <p className="error-message">Please try again later.</p>
          <button className="retry-button" onClick={fetchWorkflowInstances}>
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
}

export default ErrorNotification;
