import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faExclamationTriangle,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import ErrorNotification from "./ErrorNotification.js";
import { useApiUtils } from "../utils/apiUtils.js";
import { useAuth0 } from "@auth0/auth0-react";
import { getDomainFromEmail } from "../utils/formatUtils.js";

function Cards({
  totalWorkflows,
  totalRuns,
  errorCount,
  warningCount,
  successCount,
  toggleStatusFilter,
  isExpanded,
  setIsExpanded,
  error,
  fetchWorkflowInstances,
}) {
  const [report, setReport] = useState("");
  const [lastRefreshed, setLastRefreshed] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { fetchWorkflowsForLast7Days } = useApiUtils();
  const { getAccessTokenSilently, user } = useAuth0();
  const firstTwoCardsRef = useRef(null);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      if (isFetching) {
        return;
      }
      setIsFetching(true);

      try {
        const token = await getAccessTokenSilently();
        const domain = getDomainFromEmail(user.email);

        setReport("");

        const handleChunk = (chunk) => {
          setReport((prevReport) => {
            const updatedReport = prevReport + chunk;
            return updatedReport;
          });
        };

        await fetchWorkflowsForLast7Days(
          token,
          domain,
          handleChunk,
          abortController
        );

        const currentTime = new Date().toLocaleString();
        setLastRefreshed(currentTime);

        sessionStorage.setItem("supervisorReport", report);
        sessionStorage.setItem("lastRefreshedTime", currentTime);
        sessionStorage.setItem("dataFetched", "true");
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch request was aborted.");
        } else {
          console.error("Error fetching report:", error);
        }
      } finally {
        setIsFetching(false);
      }
    };

    const dataFetched = sessionStorage.getItem("dataFetched");
    const cachedReport = sessionStorage.getItem("supervisorReport");
    const cachedTime = sessionStorage.getItem("lastRefreshedTime");

    console.log("DATA FETCHED", dataFetched);
    console.log("CACHED REPORT", cachedReport);
    console.log("CACHED TIME", cachedTime);

    if (dataFetched && cachedReport && cachedTime) {
      console.log("Using cached report");
      setReport(cachedReport);
      setLastRefreshed(cachedTime);
    } else {
      fetchData();
      console.log("FETCHING DATA FOR REPORT: ", report);
    }

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (report) {
      sessionStorage.setItem("supervisorReport", report);
      sessionStorage.setItem("lastRefreshedTime", lastRefreshed);
      sessionStorage.setItem("dataFetched", "true");
    }
  }, [report, lastRefreshed]);

  useEffect(() => {
    if (firstTwoCardsRef.current) {
      const firstTwoCardsWidth = firstTwoCardsRef.current.offsetWidth;
      document.documentElement.style.setProperty(
        "--first-two-cards-width",
        `${firstTwoCardsWidth}px`
      );
    }
  }, [firstTwoCardsRef]);

  const handleExpandClick = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="App-cards">
      <div ref={firstTwoCardsRef} className="First-two-cards">
        <div className="App-card App-card-first">
          <div className="row">
            <span className="number">{totalWorkflows}</span>
            <span className="text">
              {totalWorkflows === 1 ? "Active Workflow" : "Active Workflows"}
            </span>
          </div>
          <div className="separator"></div>
          <div className="row">
            <span className={typeof totalRuns === "number" ? "number" : "text"}>
              {totalRuns !== null ? totalRuns : "Loading..."}
            </span>
            {typeof totalRuns === "number" && (
              <span className="text">Runs</span>
            )}
          </div>
        </div>
        <div className="App-card App-card-second">
          <div className="icon" onClick={() => toggleStatusFilter("failed")}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="status-icon-card-failed"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("failed")}>
            Failed
          </div>
          <div
            className="number failed"
            onClick={() => toggleStatusFilter("failed")}
          >
            {errorCount}
          </div>
          <div className="icon" onClick={() => toggleStatusFilter("error")}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="status-icon-card-error"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("error")}>
            Warning
          </div>
          <div className="number error">{warningCount}</div>
          <div className="icon" onClick={() => toggleStatusFilter("completed")}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="status-icon-card-success"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("completed")}>
            Success
          </div>
          <div
            className="number completed"
            onClick={() => toggleStatusFilter("completed")}
          >
            {successCount}
          </div>
        </div>
      </div>
      <div
        className={`App-card App-card-third ${isExpanded ? "expanded" : ""}`}
        onClick={handleExpandClick}
      >
        <div className="supervisor-report-header">
          <h4>
            Supervisor Report <span className="report-date-separator">•</span>
            <span className="report-date">{lastRefreshed}</span>
          </h4>
          {/* <button className="refresh-button" onClick={handleRefreshClick}>
            Refresh
          </button> */}
        </div>
        <div className="report-content">
          <pre>{report}</pre>
        </div>
      </div>
      {error && (
        <ErrorNotification
          error={error}
          fetchWorkflowInstances={fetchWorkflowInstances}
        />
      )}
    </div>
  );
}

export default Cards;
