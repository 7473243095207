/* eslint-disable */
import React from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import qurrentLogoDark from "./assets/qurrent_icon.png";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiUtils } from "./utils/apiUtils.js";
import { handleLogout } from "./utils/authUtils.js";

const ErrorOccuredPage = () => {
  const location = useLocation();
  const { logout, getAccessTokenSilently } = useAuth0();
  const apiUtils = useApiUtils();

  const { title, message, contactInfo } = location.state || {
    title: "Error",
    message: "An unknown error occurred.",
    contactInfo: "If the problem persists, contact team@qurrent.ai",
  };

  const handleReturn = async () => {
    await handleLogout({ logout, getAccessTokenSilently, apiUtils });
  };

  return (
    <div className="not-authorized-container">
      <img
        src={qurrentLogoDark}
        alt="Qurrent Logo"
        className="authentication-logo"
      />
      <h1>{title}</h1>
      <p>{message}</p>
      <p>{contactInfo}</p>
      <button onClick={handleReturn} className="back-to-main-button">
        Return to Login
      </button>
    </div>
  );
};

export default ErrorOccuredPage;
