// supervise-console/src/Breadcrumbs.js
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs({
  breadcrumbs,
  handleBackButtonClick,
  handleWorkflowClick,
  thisWorkflowId,
}) {
  return (
    <div className="fixed-top">
      <Link to="/" className="back-button" onClick={handleBackButtonClick}>
        Home
        <>&nbsp;</>
      </Link>
      <div className="breadcrumbs">
        {(breadcrumbs || []).map((crumb) => {
          return (
            <React.Fragment key={crumb.workflow_id}>
              {"/ "}
              {crumb.workflow_id === thisWorkflowId ? (
                <span className="current-breadcrumb">{crumb.name}</span>
              ) : (
                <span
                  onClick={() => handleWorkflowClick(crumb)}
                  className="not-current-breadcrumb"
                >
                  {crumb.name}
                </span>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Breadcrumbs;
